import {
  calculateIncrease,
  WidgetData,
} from "@pages/AdsAnalitics/components/Campaigns/utils";
import { AddsSalesApi } from "./adds-sales/adds-sales.api";
import {
  MathService,
  WidgetMetricKey,
  WidgetMetrics,
} from "./math/math.service";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";

export class WidgetClass {
  public widgetCount: number;
  private mathService: MathService;
  private adsSalesApi: AddsSalesApi;

  constructor() {
    this.widgetCount = 0;
    this.mathService = new MathService();
    this.adsSalesApi = new AddsSalesApi();
  }

  public getWidgetCount(): number {
    return this.widgetCount;
  }

  public setWidgetCount(count: number): void {
    this.widgetCount = count;
  }

  public async getWidgetsMetrics(
    date_start: string,
    date_end: string,
    compareStart?: string,
    compareEnd?: string,
  ): Promise<
    WidgetMetrics & {
      compare?: Partial<WidgetData>;
    }
  > {
    const metrics = await this.mathService.getAllMetrics(date_start, date_end);
    if (compareStart && compareEnd) {
      const compareMetrics = await this.adsSalesApi.getWidgetsComparePromise(
        date_start,
        date_end,
        compareStart,
        compareEnd,
      );

      return {
        ...metrics,
        compare: compareMetrics,
      };
    }

    return metrics;
  }

  public async getWidgetsData(
    selectedKeys: WidgetMetricKey[],
    date_start: string,
    date_end: string,
    compareStart?: string,
    compareEnd?: string,
  ): Promise<IWidget[]> {
    const metrics = await this.getWidgetsMetrics(
      date_start,
      date_end,
      compareStart,
      compareEnd,
    );

    const metricsData: IWidget[] = [];

    selectedKeys.forEach((key) => {
      let compareKey: string = key;
      let compareValue: string = "0";
      if (key.includes("ads"))
        compareKey = key.replace("ads", "").toLowerCase();

      if (metrics.compare[compareKey] !== undefined) {
        compareValue = calculateIncrease(
          metrics[key],
          metrics.compare[compareKey],
        ).increase;
      }

      const widget: IWidget = {
        id: key,
        name: WidgetClass.convertKeyToName(key),
        value: metrics[key]?.toString() || "0",
        originalValue: metrics[key] || 0,
        difference: compareValue,
      };

      metricsData.push(widget);
    });

    return metricsData;
  }

  public static convertKeyToName(key: string): string {
    return key
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  }
}

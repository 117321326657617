import trendUp from "@assets/icons/trendUp.svg";
import trendDown from "@assets/icons/trendDown.svg";
import { AdSlides } from "src/store/ads-analitics.store";
import { renderToStaticMarkup } from "react-dom/server";
import { ProductText } from "@pages/CROModule/components/Product/ProductText";
import { ProductDataByAsin } from "@services/amazon/products/types";
import ProductCard from "@pages/CROModule/components/Product/ProductCard";

export const tooltipFormatter = (
  params: any,
  productData?: ProductDataByAsin,
) => {
  const [timestamp, value, seriesName] = params.data;
  console.log("productData", productData);
  const productHtml = renderToStaticMarkup(
    <ProductCard
      title={productData?.item_name}
      price={productData?.price}
      image={productData?.image_link}
      hideTitle
      isSmall
    />,
  );

  if (seriesName === "Amazon Organic") {
    return `
      <div style="font-size: 14px; color: #2E2E2E; display: flex; flex-direction: column; gap: 12px;">
        <strong style="font-size: 16px;">${seriesName}</strong>
        <div style="display: flex; flex-direction: row; gap: 24px;">
          ${productHtml}
          <div display: flex; flex-direction: column; gap: 4px;>
            <span style="color: #5C5C5A;">Organic CVR</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>4.09%</strong>
              <img src="${trendUp}" alt="trend up" style="width: 16px; height: 16px;" />
              <span style="color: #80C67A;">5.2%</span>
            </div>
          </div>
          <div display: flex; flex-direction: column; gap: 4px;>
            <span style="color: #5C5C5A;">Organic CTR</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>4.09%</strong>
              <img src="${trendDown}" alt="trend down" style="width: 16px; height: 16px;" />
              <span style="color: #FE5858;">0.8%</span>
            </div>
          </div>
          <div display: flex; flex-direction: column; gap: 4px;>
            <span style="color: #5C5C5A;">Organic Sales</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>$891</strong>
              <img src="${trendDown}" alt="trend down" style="width: 16px; height: 16px;" />
              <span style="color: #FE5858;">1.2%</span>
            </div>
          </div>
          <div display: flex; flex-direction: column; gap: 4px;>
            <span style="color: #5C5C5A;">TACOS</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>$1.20</strong>
              <img src="${trendUp}" alt="trend up" style="width: 16px; height: 16px;" />
              <span style="color: #80C67A;">1.8%</span>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  return `
    <div style="font-size: 14px; color: #2E2E2E; display: flex; flex-direction: column; gap: 12px;">
      <strong style="font-size: 16px;">${seriesName}</strong>
      <div style="display: flex; flex-direction: row; gap: 24px;">
        ${productHtml}
        <div style="display: flex; flex-direction: row; gap: 24px; width: 600px; flex-wrap: wrap">
          <div style="display: flex; flex-direction: column; gap: 4px;">
            <span style="color: #5C5C5A;">Ad CVR</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>4.09%</strong>
              <img src="${trendUp}" alt="trend up" style="width: 16px; height: 16px;" />
              <span style="color: #80C67A;">5.2%</span>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; gap: 4px;">
            <span style="color: #5C5C5A;">Ad CTR</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>4.09%</strong>
              <img src="${trendDown}" alt="trend down" style="width: 16px; height: 16px;" />
              <span style="color: #FE5858;">0.8%</span>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; gap: 4px;">
            <span style="color: #5C5C5A;">Ad Spend</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>$891</strong>
              <img src="${trendDown}" alt="trend down" style="width: 16px; height: 16px;" />
              <span style="color: #FE5858;">1.2%</span>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; gap: 4px;">
            <span style="color: #5C5C5A;">Ad Sales</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>$1,371.60</strong>
              <img src="${trendUp}" alt="trend up" style="width: 16px; height: 16px;" />
              <span style="color: #80C67A;">4.7%</span>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; gap: 4px;">
            <span style="color: #5C5C5A;">ACOS</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>25.45%</strong>
              <img src="${trendUp}" alt="trend up" style="width: 16px; height: 16px;" />
              <span style="color: #80C67A;">3.2%</span>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; gap: 4px;">
            <span style="color: #5C5C5A;">ROAS</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>2.11</strong>
              <img src="${trendDown}" alt="trend down" style="width: 16px; height: 16px;" />
              <span style="color: #FE5858;">0.1%</span>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; gap: 4px;">
            <span style="color: #5C5C5A;">ROI</span>
            <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
              <strong>131%</strong>
              <img src="${trendUp}" alt="trend up" style="width: 16px; height: 16px;" />
              <span style="color: #80C67A;">4.7%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  `;
};

export const seriesConfig = [
  { name: "Amazon Ads", color: "#f39c6b" },
  { name: "Amazon Organic", color: "#81c784" },
  { name: "Organic Social", color: "#8e44ad" },
  { name: "Other Sales", color: "#3498db" },
];

const seriesColors: Record<string, string> = {
  "Amazon Ads": "#f39c6b",
  "Forecast Amazon Ads": "#FEB38A",
  "Amazon Organic": "#81c784",
  "Forecast Amazon Organic": "#98E191",
  "Organic Social": "#8e44ad",
  "Other Sales": "#3498db",
};

const dates = [
  new Date(2024, 0, 1), // Impressions
  new Date(2024, 0, 2), // Clicks
  new Date(2024, 0, 3), // Orders
];

const dataMap: Record<string, number[]> = {
  "Amazon Ads": [120, 90, 30],
  "Amazon Organic": [80, 70, 50],
  "Organic Social": [50, 30, 20],
  "Other Sales": [30, 10, 5],
};

export const getOption = (
  activeSeries: { [key: string]: boolean },
  adSlides: AdSlides,
  productData?: ProductDataByAsin,
): echarts.EChartsOption => {
  const activeSeriesConfig = seriesConfig.filter(
    (series) => activeSeries[series.name],
  );

  const isForecastNeeded = Object.values(adSlides).some((value) => value !== 0);

  if (isForecastNeeded) {
    const slidesSum =
      Object.values(adSlides).reduce((acc, value) => acc + value, 0) || 1;

    for (const series of activeSeriesConfig) {
      const originalData = dataMap[series.name];
      const adjustmentFactor = slidesSum / 2 || 0;

      dataMap[`Forecast ${series.name}`] = originalData.map(
        () => adjustmentFactor,
      );
    }
  }

  // Prepare data for the chart
  const filteredData = dates.flatMap((date) =>
    activeSeriesConfig.flatMap((series) => {
      const dateIndex = dates.findIndex((d) => d.getTime() === date.getTime());
      if (dateIndex === -1) {
        console.warn(`Date ${date} not found in dates array.`);
        return [];
      }

      const baseData = [
        date.getTime(),
        dataMap[series.name][dateIndex],
        series.name,
      ] as [number, number, string];

      const dataArray = [baseData];

      if (
        isForecastNeeded &&
        dataMap[`Forecast ${series.name}`] &&
        activeSeries[series.name]
      ) {
        const forecastData = [
          date.getTime(),
          dataMap[`Forecast ${series.name}`][dateIndex],
          `Forecast ${series.name}`,
        ] as [number, number, string];

        dataArray.push(forecastData);
      }

      return dataArray;
    }),
  );

  const colors = Object.keys(seriesColors)
    .map((key) => {
      const dataMatch = filteredData.find((data) => data[2] === key);
      if (dataMatch) {
        return seriesColors[key];
      }
      return undefined; // Explicitly return undefined
    })
    .filter((color): color is string => color !== undefined);

  return {
    title: {
      text: "Campaign Performance",
      left: "5%",
      top: "24px",
      textStyle: {
        color: "#ffffff",
      },
    },
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      backgroundColor: "#ffffff",
      borderColor: "#ffffff",
      borderRadius: 16,
      renderMode: "html",
      padding: 16,
      position: function (point, params, dom, rect, size) {
        const chartWidth = 1252;
        const centerX = chartWidth / 2;
        const threshold = chartWidth * 0.18;

        if (
          point[0] >= centerX - threshold &&
          point[0] <= centerX + threshold
        ) {
          return [point[0] - size.contentSize[0] / 2, point[1] + 15];
        }

        if (point[0] > centerX) {
          return [point[0] - size.contentSize[0] - 15, point[1]];
        }

        return [point[0] + 15, point[1]];
      },
      formatter: (params) => tooltipFormatter(params, productData),
    },
    singleAxis: {
      type: "time",
      show: false,
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
    graphic: [
      {
        type: "text",
        left: "16.5%",
        top: "95%",
        style: {
          text: "Impressions",
          align: "center",
          fill: "#ffffff",
        },
      },
      {
        type: "text",
        left: "50%",
        top: "95%",
        style: {
          text: "Clicks",
          align: "center",
          fill: "#ffffff",
        },
      },
      {
        type: "text",
        left: "83.5%",
        top: "95%",
        style: {
          text: "Orders",
          align: "center",
          fill: "#ffffff",
        },
      },
    ],
    series: [
      {
        type: "themeRiver",
        data: filteredData,
        animation: false,
        label: {
          show: false,
          color: "#ffffff",
        },
        color: colors,
        emphasis: {
          disabled: false,
          focus: "self",
          itemStyle: {
            opacity: 1,
          },
        },
        select: {
          disabled: true,
        },
        blur: {
          itemStyle: {
            opacity: 0.2,
          },
        },
        universalTransition: true,
      },
    ],
  };
};

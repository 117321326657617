export function convertValue(inputString: string, name: string) {
  const cleanedString = inputString.replace(/[^\d.,$%]/g, "");

  const lowerKey = name.toLowerCase();

  const isDollar = cleanedString.startsWith("$");
  const isPercentage = cleanedString.endsWith("%");

  const valueString = cleanedString.replace(/[$%]/g, "");

  const firstDelimiterIndex = valueString.search(/[.,]/);

  let integerPart = valueString;
  let decimalPart = "";

  if (firstDelimiterIndex !== -1) {
    integerPart = valueString.slice(0, firstDelimiterIndex);
    decimalPart = valueString.slice(firstDelimiterIndex + 1);
  }

  integerPart = integerPart.replace(/[.,]/g, "");

  const formattedIntegerPart = parseInt(integerPart, 10).toLocaleString(
    "en-US",
  );

  if (decimalPart.length > 2) {
    decimalPart = decimalPart.substring(0, 2);
  }

  let result = formattedIntegerPart;
  if (decimalPart.length > 0) {
    result += "." + decimalPart;
  }

  if (isDollar) {
    result = "$" + result;
  }

  if (isPercentage) {
    result += "%";
  }

  if (result.endsWith(".") || result.endsWith(",")) {
    result = result.slice(0, -1);
  }

  if (
    lowerKey.includes("cvr") ||
    lowerKey.includes("ctr") ||
    lowerKey.includes("acos")
  ) {
    return `${(Number(inputString.slice(0, inputString.length - 1)) * 100).toFixed(2)}%`;
  }

  return result;
}

export const formattedKeys = (spiderDots) =>
  Object.keys(spiderDots).map((key) =>
    key
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
  );

import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import ProductHeaderTitle from "../components/ProductHeaderTitle";
import { useProductsStore } from "src/store/product.state";
import product1 from "@assets/img/noImage.jpg";
import { GraphsService } from "@services/amazon/graph/graph.service";
import {
  getAsin,
  getDateRange,
  getRat,
} from "@pages/Compare/components/Products/ProductCard/constants";
import { useProductStore } from "src/store/overviewProduct.state";
import { mockData } from "@pages/Compare/components/Products/mock";
import { CategoriesService } from "@services/amazon/categories/categories.service";
interface ProductCardProps {
  price: number | null;
  image: string | null;
  title: string | null;
  isUpdated?: boolean;
  hideTitle?: boolean;
  isSmall?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({
  price,
  image,
  title,
  isUpdated,
  hideTitle,
  isSmall,
}) => {
  const { productPublic } = useProductsStore();
  const { selectedProduct, selectedProductToCompare } = useProductStore();
  const [totalReviews, setTotalReviews] = useState<number>(0);
  const [ratings, setRatings] = useState<{ [key: string]: number | null }>({});
  const [spiders, setSpiders] = useState<any>(null);
  const [scoreMain, setScoreMain] = useState<{ [key: string]: number | null }>(
    {},
  );

  const rat = getRat(selectedProduct, mockData[0]);
  const getScoresForAsins = (asins: string[]) => {
    const scores: { [key: string]: number | null } = {};

    asins.forEach((asin) => {
      let found = false;
      for (const spider of spiders) {
        const item = spider.items.find((item) => item.asin === asin);
        if (item) {
          scores[asin] = item.score;
          found = true;
          break;
        }
      }
      if (!found) {
        scores[asin] = null;
      }
    });

    return scores;
  };

  useEffect(() => {
    if (
      spiders &&
      Array.isArray(spiders) &&
      selectedProductToCompare &&
      selectedProductToCompare.length > 0
    ) {
      const asins = selectedProductToCompare.flat().map((p) => p.asin);
      const newRatings = getScoresForAsins(asins);
      setRatings((prevRatings) => ({
        ...prevRatings,
        ...newRatings,
      }));
    }

    if (selectedProduct && selectedProduct.asin && spiders) {
      const score = getScoresForAsins([selectedProduct.asin]);

      setRatings((prevRatings) => ({
        ...prevRatings,
        ...score,
      }));
    }
  }, [selectedProductToCompare, selectedProduct, spiders]);
  const fetchCategories = async () => {
    const spider = new CategoriesService();
    try {
      if (selectedProduct?.asin.length > 1) {
        const spidersData = await spider.getCategories(selectedProduct?.asin);
        setSpiders(spidersData);
        setScoreMain(spidersData);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      return null;
    }
  };
  useEffect(() => {
    if (selectedProduct) {
      fetchCategories();
    }
  }, [selectedProduct]);

  const handleReviewScore = async () => {
    const spider = new GraphsService();
    const { date_start, date_end } = getDateRange();
    const spiderDot = await spider.getGraph(
      selectedProduct.asin,
      date_start,
      date_end,
    );
    setTotalReviews(spiderDot.length);
  };

  useEffect(() => {
    if (selectedProduct) {
      handleReviewScore();
    }
  }, [selectedProduct]);

  return (
    <div className={isSmall && styles.small}>
      {!hideTitle && <ProductHeaderTitle title="External product page" />}
      <div className={styles.productCard}>
        <div className={styles.card}>
          <img
            src={image ?? product1}
            alt="Sugarbear Hair Vitamins"
            className={styles.productImage}
          />
          <div className={styles.productInfo}>
            <p>
              {isUpdated ? (
                <mark>{title}</mark>
              ) : (
                title || "Gummy · 60 Count (Pack of 1)"
              )}
            </p>
            <div className={styles.rating}>
              <span className={styles.ratingValue}>
                ⭐{" "}
                {ratings[selectedProduct?.asin] !== undefined
                  ? ratings[selectedProduct.asin]?.toFixed(1)
                  : (rat?.toFixed(1) ?? "N/A")}
              </span>
              <span>Rating: {totalReviews}</span>
            </div>
            <p className={styles.price}>
              ${price} <span>($0.50/Count)</span>
            </p>
            <p>${productPublic?.price} with Subscribe & Save discount</p>
            {!isSmall && (
              <>
                <p className={styles.prime}>prime</p>
                <p>
                  FREE delivery Thu, Apr 25 on $35 of items shipped by Amazon
                </p>
                <p>Or fastest delivery Today 7 AM - 11 AM</p>
                <p>Small Business</p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
